const rowData = {
    base: '',
};

const build = (url, params) => {
    // Find and replace path parameters (e.g. :abc, :xyz)
    url = url.replace(/:([^\/]+)/g, (_, key) => {
        if (params[key] !== undefined) {
            const value = params[key];
            delete params[key]; // Remove the replaced param from the params object
            return value;
        }
        return `:${key}`; // Leave the original param if no match found
    });

    // Append remaining params as query parameters
    let queryParams = new URLSearchParams(params).toString();
    if (queryParams) {
        url += (url.includes('?') ? '&' : '?') + queryParams;
    }

    rowData.base = rowData.base ? rowData.base : jQuery('body').attr('data-url');

    return rowData.base + '/' + url;
};
const setQuery = (params) => {
    window.history.replaceState(null, '', '?' + params);
};

export default {
    base: () => rowData.base,
    build,
    current: () => '',
    set: (url) => window.history.pushState({}, '', url),
    setQuery,
    queryGet: (key) => {
        let params = new URLSearchParams(window.location.search);
        if (params.has(key)) {
            return params.get(key);
        }
        return null;
    },
    queryAdd: (key, val) => {
        let params = new URLSearchParams(window.location.search);
        if (params.has(key)) {
            params.set(key, val);
        } else {
            params.append(key, val);
        }
        setQuery(params.toString());
    },
    queryRemove: (key) => {
        let params = new URLSearchParams(window.location.search);
        if (params.has(key)) {
            params.delete(key);
        }
        setQuery(params.toString());
    },
};
