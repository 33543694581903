import DOMPurify from 'dompurify';

const safe = {
    value: (str) => {
        const escapeChars = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#39;',
            '/': '&#x2F;',
            '`': '&#x60;',
            '=': '&#x3D;',
        };
        return str ? str.toString().replace(/[&<>"'`=\/]/g, (char) => escapeChars[char]) : str;
    },
    content: (str) => DOMPurify.sanitize(str),

    getNumber: (value) => {
        if (value === undefined) return 0;
        if (value === null) return 0;
        if (!value) return 0;

        value = value.replace(/,/g, '');

        if (isNaN(value)) return 0;
        return value;
    },

    int: (value) => parseInt(safe.getNumber(value)),
    float: (value) => parseFloat(safe.getNumber(value)),
    round: (value, upto = 2) => Math.round((value + Number.EPSILON) * 100) / 100,

    html: (content, defaultValue = '-') => {
        return jQuery('<div />')
            .text(content ?? defaultValue)
            .html();
    },

    makeId: (length = 10) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    },

    randomString: () => Math.random().toString(36).substring(2, 9),

    makeSlug: (ele) => {
        let txt = ele.value.toLowerCase();
        txt = txt.replace(' ', '-');
        txt = txt.replace(/[^0-9a-z-]/gi, '');
        ele.value = txt;
    },

    highlightText: (text, searchTerm) => {
        // Create a regular expression to match the search term (case insensitive)
        const regex = new RegExp(`(${safe.value(searchTerm)})`, 'gi');

        // Replace the matched text with <mark> tags
        return safe.value(text).replace(regex, '<mark>$1</mark>');
    },
};

export default safe;
