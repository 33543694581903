import { mToast, mLoader } from '@helpers'

const errorMessage = (response) =>
  response?.statusText != 'abort'
    ? response?.responseJSON?.message || response?.statusText
    : null

const error = (response, save) =>
  save
    ? mToast.save(0, errorMessage(response))
    : mToast.show(0, errorMessage(response))
const success = (response, save) =>
  response.message
    ? save
      ? mToast.save(1, response.message)
      : mToast.show(1, response.message)
    : ''

const call = function (params, cb) {
  let loading = params.loader ?? true
  let message = params.messages ?? true
  delete params.loader
  delete params.messages

  if (params.formData) {
    delete params.formData
    params.dataType = 'json'
    params.mimeType = 'multipart/form-data'
    params.contentType = false
    params.processData = false
  }

  return jQuery.ajax({
    // headers: {
    //     'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content')
    // },
    type: 'POST',
    beforeSend: () => {
      if (loading) mLoader(1)
    },
    success: function (response) {
      if (message) success(response)
      cb(1, response)
    },
    error: function (err) {
      if (message) error(err)
      cb(0, err)
    },
    complete: () => {
      if (loading) mLoader(0)
    },
    ...params,
  })
}
const get = (url, data, cb, extra = {}) =>
  call(
    { url, type: 'GET', data, ...extra },
    (success, response) => cb && cb(success, response)
  )
const post = (url, data, cb, extra = {}) =>
  call(
    { url, data, ...extra },
    (success, response) => cb && cb(success, response)
  )

export const mAjax = {
  call,
  get,
  post,
  error,
  errorMessage,
  success,
}

export default mAjax
