export default function (status, message) {
    let div = jQuery('.loader-full');
    if (!div.length) return;

    div.find('span').text(message ?? '');
    if (status) {
        div.removeClass('d-none');
        jQuery('body').css('cursor', 'wait');
    } else {
        div.addClass('d-none');
        jQuery('body').css('cursor', 'unset');
    }
}
