import { mSafe } from '@helpers';

export default {
    show: (status, message) => {
        if (message) {
            if (status === 1 || status === true) {
                toastr['success'](mSafe.value(message), 'Success', {
                    closeButton: true,
                    tapToDismiss: false,
                    progressBar: true,
                });
            } else {
                ((code, message) => {
                    let title = 'Error';
                    if (code === 400) title = `Error (${code})`;
                    if (code === 403) title = `Error (${code})`;
                    if (code === 500) title = `Error (${code})`;

                    toastr['error'](mSafe.value(message), title, {
                        closeButton: true,
                        tapToDismiss: false,
                        progressBar: true,
                    });
                })(status, message);
            }
        }
    },

    save: (status, message) => localStorage.setItem(status ? 'successM' : 'errorM', message),

    showSaved: function () {
        if (localStorage.getItem('successM')) {
            this.show(1, localStorage.getItem('successM'));
            localStorage.removeItem('successM');
        }
        if (localStorage.getItem('errorM')) {
            this.show(0, localStorage.getItem('errorM'));
            localStorage.removeItem('errorM');
        }
    },
};
