// import '../css/app.scss';

import { mAjax, mAddress, mDataTable, mFeatherApply, mHtml, mLoader, mTooltip, mSafe, mMenuHorizontal, mMasterSearch, mToast, mDate, mFieldFormatter } from '@helpers';

const single = () => {
    const init = () => {
        // jQuery validator custom rules
        jQuery.validator.addMethod('phone_ruler', (text, ele) => !jQuery(ele).is(':required') || (text.length > 9 && text.match(/\(\d\d\d\) \d\d\d - \d\d\d\d/i)), 'Please specify a valid phone number, must be as (000) 000 - 0000');
        jQuery.validator.addMethod('tax_id_ruler', (text, ele) => !jQuery(ele).is(':required') || (text.length === 10 && text.match(/^(\d{2}-\d{7})$/)), 'Please specify a valid value, must be as 00-0000000');
        jQuery.validator.addMethod('ssn_id_ruler', (text, ele) => !jQuery(ele).is(':required') || (text.length === 11 && text.match(/^(\d{3}-\d{2}-\d{4})$/)), 'Please specify a valid value, must be as 000-00-0000');
        jQuery.validator.addMethod('zip_code_ruler', (text, ele) => !jQuery(ele).is(':required') || (text.length === 5 && text.match(/^(\d{5})$/)), 'Please specify a valid zip code, must be 5 digits only');
        jQuery.validator.addMethod('month_year_ruler', (text, ele) => !jQuery(ele).is(':required') || (text.length === 7 && text.match(/^(\d{2}\/\d{4})$/)), 'Please specify a valid value, must be as 00/0000');
        jQuery.validator.addMethod('containsLowercase', (value, ele) => (jQuery(ele).is(':required') ? /[a-z]/.test(value) : true), 'Password must contain a lowercase letter');
        jQuery.validator.addMethod('containsUppercase', (value, ele) => (jQuery(ele).is(':required') ? /[A-Z]/.test(value) : true), 'Password must contain an uppercase letter');
        jQuery.validator.addMethod('containsNumber', (value, ele) => (jQuery(ele).is(':required') ? /\d/.test(value) : true), 'Password must contain a number');
        jQuery.validator.addMethod('containsSpecialChar', (value, ele) => (jQuery(ele).is(':required') ? /[^a-zA-Z0-9\s]/.test(value) : true), 'Password must contain a special character or a space');
        jQuery.validator.addMethod('noLeadingTrailingSpace', (value, ele) => value?.trim() === value, 'Password must not contain leading or trailing spaces');

        // jQuery extra functions
        jQuery.fn = Object.assign(jQuery.fn, {
            fAddressSuggester: function (cb) {
                this.each((index, element) => mAddress(element, cb));
                return this;
            },
            fAddressFields: function (fields = false) {
                let data = {};
                this.each(function () {
                    let div = jQuery(this);
                    data = {
                        address_1: !fields ? div.find(`.bas-address-1`).val() : div.find(`.bas-address-1`),
                        address_2: !fields ? div.find(`.bas-address-2`).val() : div.find(`.bas-address-2`),
                        city: !fields ? div.find(`.bas-city`).val() : div.find(`.bas-city`),
                        state_id: !fields ? div.find(`.bas-state-id`).val() : div.find(`.bas-state-id`),
                        zip_code: !fields ? div.find(`.bas-zip-code`).val() : div.find(`.bas-zip-code`),
                    };
                });
                return data;
            },

            fAmountGet: function () {
                let value;
                this.each(function () {
                    value = mSafe.float(mFieldFormatter.getValue(jQuery(this)));
                });
                return value;
            },
            fAmountSet: function (value) {
                this.each(function () {
                    mFieldFormatter.setValue(jQuery(this), value);
                });
                return this;
            },

            fContent: function (content, html = false) {
                this.each(function () {
                    if (html) jQuery(this).html(content);
                    else jQuery(this).text(content);
                });
                return this;
            },

            fDateApply: function () {
                this.each(function () {
                    if (jQuery(this).hasClass('flatpickr-input')) {
                        jQuery(this).fDatePickerDestroy();
                    }
                    if (jQuery(this).hasClass('cls-datepicker')) {
                        mDate.apply(jQuery(this), 'date');
                    } else if (jQuery(this).hasClass('cls-datetimepicker')) {
                        mDate.apply(jQuery(this), 'datetime');
                    } else if (jQuery(this).hasClass('cls-timepicker')) {
                        mDate.apply(jQuery(this), 'time');
                    }
                });
                return this;
            },
            fDateOnChange: function (cb) {
                this.each(function () {
                    if (jQuery(this)[0]._flatpickr) {
                        jQuery(this)[0]._flatpickr.config.onChange.push(function (selectedDates, dateStr, instance) {
                            cb(dateStr, mDate.db(dateStr));
                        });
                    }
                });
                return this;
            },
            fDatePickerDestroy: function () {
                this.each(function () {
                    if (jQuery(this)[0]._flatpickr) {
                        jQuery(this)[0]._flatpickr.destroy();
                    }
                });
                return this;
            },
            fDateSet: function (value) {
                this.each(function () {
                    if (!value) return;
                    if (jQuery(this)[0]._flatpickr) {
                        jQuery(this)[0]._flatpickr.setDate(mDate.date(value));
                    } else {
                        jQuery(this).val(mDate.date(value));
                    }
                });
                return this;
            },
            fDateClear: function () {
                this.each(function () {
                    if (jQuery(this)[0]._flatpickr) {
                        jQuery(this)[0]._flatpickr.setDate('');
                    } else {
                        jQuery(this).val('');
                    }
                });
                return this;
            },

            fEditor: function () {
                this.each((index, element) => {
                    try {
                        ClassicEditor.create(element, {
                            basicEntities: false,
                            fillEmptyBlocks: false,
                            // licenseKey: '',
                            fontColor: {
                                colorPicker: {
                                    // Use 'hex' format for output instead of 'hsl'.
                                    format: 'hex',
                                },
                                colors: [
                                    { color: 'hsl(0, 0%, 0%)', label: 'Black' },
                                    { color: 'hsl(0, 0%, 30%)', label: 'Dim grey' },
                                    { color: 'hsl(0, 0%, 60%)', label: 'Grey' },
                                    {
                                        color: 'hsl(0, 0%, 90%)',
                                        label: 'Light grey',
                                    },
                                    {
                                        color: 'hsl(0, 0%, 100%)',
                                        label: 'White',
                                        hasBorder: true,
                                    },
                                    { color: 'hsl(0, 75%, 60%)', label: 'Red' },
                                    { color: 'hsl(30, 75%, 60%)', label: 'Orange' },
                                    { color: 'hsl(60, 75%, 60%)', label: 'Yellow' },
                                    {
                                        color: 'hsl(90, 75%, 60%)',
                                        label: 'Light green',
                                    },
                                    { color: 'hsl(120, 75%, 60%)', label: 'Green' },
                                    {
                                        color: 'hsl(150, 75%, 60%)',
                                        label: 'Aquamarine',
                                    },
                                    {
                                        color: 'hsl(180, 75%, 60%)',
                                        label: 'Turquoise',
                                    },
                                    {
                                        color: 'hsl(210, 75%, 60%)',
                                        label: 'Light blue',
                                    },
                                    { color: 'hsl(240, 75%, 60%)', label: 'Blue' },
                                    {
                                        color: 'hsl(270, 75%, 60%)',
                                        label: 'Purple',
                                    },
                                    { color: '#663890', label: 'Theme Purple' },
                                ],
                            },
                            fontBackgroundColor: {
                                // Do not display the color picker.
                                colorPicker: true,
                                colors: [
                                    { color: 'hsl(0, 0%, 0%)', label: 'Black' },
                                    { color: 'hsl(0, 0%, 30%)', label: 'Dim grey' },
                                    { color: 'hsl(0, 0%, 60%)', label: 'Grey' },
                                    {
                                        color: 'hsl(0, 0%, 90%)',
                                        label: 'Light grey',
                                    },
                                    {
                                        color: 'hsl(0, 0%, 100%)',
                                        label: 'White',
                                        hasBorder: true,
                                    },
                                    { color: 'hsl(0, 75%, 60%)', label: 'Red' },
                                    { color: 'hsl(30, 75%, 60%)', label: 'Orange' },
                                    { color: 'hsl(60, 75%, 60%)', label: 'Yellow' },
                                    {
                                        color: 'hsl(90, 75%, 60%)',
                                        label: 'Light green',
                                    },
                                    { color: 'hsl(120, 75%, 60%)', label: 'Green' },
                                    {
                                        color: 'hsl(150, 75%, 60%)',
                                        label: 'Aquamarine',
                                    },
                                    {
                                        color: 'hsl(180, 75%, 60%)',
                                        label: 'Turquoise',
                                    },
                                    {
                                        color: 'hsl(210, 75%, 60%)',
                                        label: 'Light blue',
                                    },
                                    { color: 'hsl(240, 75%, 60%)', label: 'Blue' },
                                    {
                                        color: 'hsl(270, 75%, 60%)',
                                        label: 'Purple',
                                    },
                                    { color: '#663890', label: 'Theme Purple' },
                                ],
                            },
                            data: {
                                emptyParagraph: false, // Prevents the generation of empty paragraph
                            },
                        })
                            .then((editor) => {
                                editor.sourceElement.setAttribute('data-editor-id', editor.id);
                                if (!window.__ckEditors) {
                                    window.__ckEditors = {};
                                }
                                window.__ckEditors[editor.id] = editor;

                                window.editor = editor;
                            })
                            .catch((error) => {
                                console.error('Oops, something went wrong!');
                                console.error(error);
                                // console.error('Please, report the following error on https://github.com/ckeditor/ckeditor5/issues with the build id and the error stack trace:');
                                // console.warn('Build id: fb7av387ng19-lwkgdblut0e6');
                            });
                    } catch (error) {
                        console.log(error);
                    }
                });
                return this;
            },

            fFullSetup: function () {
                this.each(function () {
                    let element = jQuery(this);

                    element.find('.cls-text-editor').fEditor();
                    element.find('.select2').fSelect2();
                    element.find('.cls-datepicker, .cls-datetimepicker, .cls-timepicker').fDateApply();
                    mFieldFormatter.amount(element.find('.cls-input-amount'));
                    mFieldFormatter.phone(element.find('.cls-input-phone'));
                    mFieldFormatter.ssnId(element.find('.cls-input-ssn-id'));
                    mFieldFormatter.taxId(element.find('.cls-input-tax-id'));
                    mFieldFormatter.zipCode(element.find('.cls-input-zip-code'));
                    mFieldFormatter.monthYear(element.find('.cls-month-picker'));

                    element.find('.block-address').fAddressSuggester();

                    mFeatherApply();
                });
                return this;
            },

            fInt: function () {
                let value;
                this.each(function () {
                    value = mSafe.int(this.value);
                });
                return value;
            },

            fHtmlRender: function (children = [], clear = false) {
                this.each(function () {
                    if (Array.isArray(children) && children?.length) {
                        let element = jQuery(this);
                        if (clear) {
                            element.text('');
                        }
                        element.append(children);
                    }
                });
                return this;
            },

            fModalShown: function (cb) {
                this.each(function () {
                    if (cb) jQuery(this).on('shown.bs.modal', cb);
                    else jQuery(this).off('shown.bs.modal', cb);
                });
                return this;
            },
            fModalHide: function (cb) {
                this.each(function () {
                    if (cb) jQuery(this).on('hide.bs.modal', cb);
                    else jQuery(this).off('hide.bs.modal', cb);
                });
                return this;
            },
            fModalHidden: function (cb) {
                this.each(function () {
                    if (cb) jQuery(this).on('hidden.bs.modal', cb);
                    else jQuery(this).off('hidden.bs.modal', cb);
                });
                return this;
            },

            fReadOnly: function (value) {
                this.each(function () {
                    jQuery(this).prop('readonly', value);
                });
                return this;
            },
            fRequired: function (flag) {
                this.each(function () {
                    jQuery(this).prop('required', flag);

                    jQuery(this)
                        .closest('.form-group')
                        .find('.form-label')
                        .find('.astrics')
                        .html(flag ? mHtml.astrics() : '');
                });
                return this;
            },

            fSelectUpdate: function (list) {
                this.each(function () {
                    let element = jQuery(this);
                    let multiple = !!element.attr('multiple');
                    let firstBlank = element.attr('data-blank') !== 'false';
                    let selected = element.val();

                    const subRender = function (list) {
                        return list
                            .map((item) => {
                                let attrs = [];
                                for (const property in item?.attrs || {}) {
                                    attrs.push(`property="${item?.attrs[property]}"`);
                                }

                                return `<option value="${item.value}" ${attrs.join(' ')}>${mSafe.value(item.label)}</option>`;
                            })
                            .join('');
                    };

                    if (list.length) {
                        let groups = {};
                        list.forEach((item) => {
                            item.group = item?.group || 'NA';
                            if (!groups[item?.group]) {
                                groups[item?.group] = [];
                            }
                            groups[item?.group].push(item);
                        });

                        let options = Object.keys(groups)
                            .map((group) => (group === 'NA' ? subRender(groups[group]) : `<optgroup label="${group}">${subRender(groups[group])}</optgroup>`))
                            .join('');

                        element.html((firstBlank && !multiple ? `<option value=""></option>` : ``) + options);
                    } else {
                        selected = null;
                        element.html(``);
                    }
                    element.val(selected).fSelect2().fSelectActions();
                });
                return this;
            },
            fSelectValue: function (value, setFirstSelect = false) {
                this.each(function () {
                    jQuery(this).val(value).fSelect2().fSelectActions();
                    setFirstSelect && jQuery(this).fSelectFirst();
                });
                return this;
            },
            fSelectFirst: function () {
                this.each(function () {
                    let element = jQuery(this);
                    if (element.find('option').length) {
                        element.val(element.find('option:first').val()).fSelectActions().fSelect2();
                    }
                });
                return this;
            },
            fSelectActions: function () {
                this.each(function () {
                    let element = jQuery(this);
                    if (element.find('option').length) {
                        let multiple = element.attr('multiple') !== undefined;
                        let viewLink = element.closest('.mcp-dropdown-wrapper').find('.mcp-view-dropdown');
                        if (viewLink.length) {
                            viewLink.addClass('d-none');
                            if (!multiple) {
                                let option = element.find('option:selected');
                                if (option.length && option.val()) {
                                    let url = viewLink.attr('data-replace-url');
                                    if (url) url = url.replace('_REPLACE_', option.val());
                                    else url = option.attr('data-view');
                                    if (url) {
                                        viewLink.attr('href', url);
                                        viewLink.removeClass('d-none');
                                    }
                                }
                            }
                        }
                    }
                });
                return this;
            },
            fSelect2: function (value) {
                this.each(function () {
                    let element = jQuery(this);

                    if (value !== undefined) {
                        element.val(value);
                    }

                    if (element.hasClass('select2-hidden-accessible')) {
                        element.select2('destroy');
                    }

                    let args = {
                        dropdownAutoWidth: true,
                        width: '100%',
                        allowClear: !(element.attr('data-allow-clear') === 'false'),
                        placeholder: 'Please Select',
                        dropdownParent: element.parent(),
                    };

                    if (element.attr('data-target-to') && jQuery(element.attr('data-target-to')).length) {
                        args.dropdownParent = jQuery(element.attr('data-target-to'));
                    }

                    element.select2(args).change(function () {
                        // jQuery(this).valid();
                    });
                });
                return this;
            },

            fShow: function (flag) {
                this.each(function () {
                    let div = jQuery(this).closest('.col-field');
                    if (flag) {
                        div.removeClass('d-none');
                    } else {
                        div.addClass('d-none');
                    }
                });
                return this;
            },

            // Table Related Functions --------------------------------
            fTableColspan: function () {
                let colspan = 1;
                this.each(function () {
                    colspan = jQuery(this).find('thead').find('tr:first-child').find('th').length;
                });
                return colspan;
            },
            fTableBody: function (rows, clear = false) {
                this.each(function () {
                    jQuery(this).find('tbody').fHtmlRender(rows, clear);
                });
                return this;
            },
            fTableEmpty: function (message) {
                this.each(function () {
                    let element = jQuery(this);
                    jQuery('<td />')
                        .text(message ?? 'No records found.')
                        .attr({ colspan: element.fTableColspan() })
                        .appendTo(element.find('tbody').text(''));
                });
                return this;
            },
            fTableError: function (message) {
                this.each(function () {
                    let element = jQuery(this);
                    jQuery('<td class="text-danger" />')
                        .text(message ?? 'Unknown error occurred.')
                        .attr({ colspan: element.fTableColspan() })
                        .appendTo(element.find('tbody').text(''));
                });
                return this;
            },
            fTableLoading: function () {
                this.each(function () {
                    let element = jQuery(this);
                    jQuery('<td />').text('Loading...').attr({ colspan: element.fTableColspan() }).appendTo(element.find('tbody').text(''));
                });
                return this;
            },

            fTooltip: function (message, placement = 'top') {
                this.each((index, element) => jQuery(element).attr('title', message).attr('data-toggle', 'tooltip').attr('data-html', true).attr('data-placement', placement));
                return this;
            },

            // fTooltip: function (title, icon = 'i') {
            //     this.each(function () {
            //         if (icon === 'i') icon = 'info';
            //         else if (icon === 'q') icon = 'help-circle';

            //         let container = jQuery(this).closest('.form-group').find('.form-label').find('.f-tooltip');
            //         if (title) {
            //             container.html(`<a href="javascript:;" ${mTooltip.apply(title)}><i data-feather="${icon}" width="24" height="24"></i></a>`);
            //             mFeatherApply();
            //         } else {
            //             container.html('');
            //         }
            //     });
            //     return this;
            // },

            fView: function () {
                this.each((index, element) => element.scrollIntoView({ behavior: 'smooth', block: 'start' }));
                return this;
            },
            fOff: function (key) {
                this.each(function () {
                    jQuery(this).off(key, () => null);
                });
                return this;
            },
        });

        jQuery.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content'),
            },
            data: {},
        });

        jQuery('body').tooltip({
            selector: '[data-toggle="tooltip"]',
            container: 'body',
            html: true,
            // trigger: 'click',
        });

        // Password toggle functionality
        jQuery(document).on('click', '.form-password-toggle .input-group-text', function (e) {
            e.preventDefault();
            let icon = jQuery(this),
                input = icon.closest('.form-password-toggle').find('input');

            if (input.attr('type') === 'text') {
                input.attr('type', 'password');
                if (feather) {
                    icon.find('svg').replaceWith(feather.icons['eye'].toSvg({ class: 'font-small-4' }));
                }
            } else if (input.attr('type') === 'password') {
                input.attr('type', 'text');
                if (feather) {
                    icon.find('svg').replaceWith(feather.icons['eye-off'].toSvg({ class: 'font-small-4' }));
                }
            }
        });
    };

    const afterPageLoad = () => {
        jQuery('body').fFullSetup();
        mFeatherApply();
        mLoader(0);

        // SHOW MESSAGES ----------------------------------------------------------------
        mToast.show(1, jQuery('body').attr('data-message-success') || '');
        mToast.show(0, jQuery('body').attr('data-message-error') || '');
        mToast.showSaved();
    };

    const authInit = () => {
        init();
        afterPageLoad();
    };

    const appInit = () => {
        init();

        // MASTER SEARCH
        const masterSearch = mMasterSearch();
        masterSearch.init();

        // MENU RELATED THINGS
        mMenuHorizontal().init();

        jQuery(document)
            .on('mouseenter', '.vertical-layout.menu-collapsed .main-menu', function (e) {
                jQuery(this).addClass('expanded');
            })
            .on('mouseleave', '.vertical-layout.menu-collapsed .main-menu', function (e) {
                jQuery(this).removeClass('expanded');
            });

        jQuery(document).on('click', '.vertical-layout .header-new .menu-toggle', function (e) {
            jQuery('body').removeClass('menu-hide').addClass('menu-open');
            jQuery('.sidenav-overlay').addClass('show');
            jQuery('.vertical-layout .main-menu').addClass('expanded');
        });
        jQuery(document).on('click', '.vertical-layout .main-menu .has-sub > a', function (e) {
            if (jQuery(this).closest('.has-sub').hasClass('open')) {
                jQuery(this).closest('.has-sub').removeClass('open');
            } else {
                jQuery('.vertical-layout .main-menu .has-sub').removeClass('open');
                jQuery(this).closest('.has-sub').toggleClass('open');
            }
        });

        jQuery(document).on('keyup', 'body', function (e) {
            if (e.key === 'Escape') {
                jQuery('.sidenav-overlay, .modern-nav-toggle').trigger('click');
            }
        });

        // Modal Events
        jQuery(document).on('shown.bs.modal', '.modal', function (e) {
            mFeatherApply();
        });

        jQuery('.mcp-dropdown').fSelectActions();

        jQuery(document).on('click', '.mcp-refresh-dropdown', function (e) {
            e.preventDefault();
            let element = jQuery(this).closest('.mcp-dropdown-wrapper').find('.mcp-dropdown');
            if (!element) {
                return false;
            }

            let filterCb = () => {};
            if (element.attr('data-filter')) {
                if (window[element.attr('data-filter')]) {
                    filterCb = window[element.attr('data-filter')];
                }
            }

            mAjax.call(
                {
                    type: 'GET',
                    url: jQuery(this).attr('href'),
                    data: filterCb(),
                },
                (success, response) => {
                    if (success) {
                        element.fSelectUpdate(response.list || []).fSelectActions();
                    }
                }
            );
        });
        jQuery(document).on('change', '.mcp-dropdown', function (e) {
            jQuery(this).fSelectActions();
        });
        jQuery(document).on('select2:opening', 'select', function (e) {
            if (jQuery(e.target).attr('data-target-class')) {
                setTimeout(() => {
                    jQuery('.select2-container').addClass(jQuery(e.target).attr('data-target-class'));
                }, 5);
            }
        });

        jQuery(document).on('click', '.read-more-container .read-more-handler', function (e) {
            e.preventDefault();
            let type = jQuery(this).find('strong').text();
            if (type === 'more') {
                jQuery(this).closest('.read-more-container').find('.read-more-content').text(jQuery(this).attr('data-large'));
                jQuery(this).find('strong').text('less');
            } else {
                jQuery(this).closest('.read-more-container').find('.read-more-content').text(jQuery(this).attr('data-short'));
                jQuery(this).find('strong').text('more');
            }
        });
        jQuery(document).on('click', '.read-more-list .enlarge-list', function (e) {
            e.preventDefault();
            jQuery(this).closest('.read-more-list').find('.data-content').toggleClass('d-none');
        });

        jQuery(document).on('click', '.content-overlay', function (e) {
            masterSearch.close();
            // jQuery('.app-content').removeClass('show-overlay');
        });
        jQuery(document).on('keydown', 'body', function (event) {
            if (event.key === 'Escape') {
                masterSearch.close();
                mDataTable.closeQuickAdds();
                mDataTable.closeQuickEdits();
            }
        });

        // Card whole header make collapsible
        jQuery(document).on('click', '.card-header.can-collapse', function (e) {
            e.preventDefault();
            if (['svg'].includes(e.target.localName)) {
                return;
            }

            jQuery(this).closest('.card').children('.card-content').collapse('toggle');
            jQuery(this).closest('.card').find('[data-action="collapse"]').toggleClass('rotate');
        });

        afterPageLoad();
    };

    const destroy = () => {};
    return {
        appInit,
        authInit,
    };
};
export default single;
